var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.communicationType.communicationTypeImagePath,
            _vm.communicationType.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.communicationType.communicationTypeImageIsDefault &&
          _vm.checkPrivilege(_vm.hasCommunicationTypeDeleteImage())},on:{"changeValue":function($event){_vm.communicationType.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.communicationType.fullCode,"title":_vm.$t('CommunicationTypes.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.communicationType.fullCode = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-communicationTypeNameAr"),"errors":_vm.errors_communicationTypeNameAr,"value":_vm.communicationType.communicationTypeNameAr,"title":_vm.$t('CommunicationTypes.nameAr'),"imgName":'communicationTypes.svg'},on:{"changeValue":function($event){_vm.communicationType.communicationTypeNameAr = $event;
            _vm.$v.communicationType.communicationTypeNameAr.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-communicationTypeNameEn"),"errors":_vm.errors_communicationTypeNameEn,"value":_vm.communicationType.communicationTypeNameEn,"title":_vm.$t('CommunicationTypes.nameEn'),"imgName":'communicationTypes.svg'},on:{"changeValue":function($event){_vm.communicationType.communicationTypeNameEn = $event;
            _vm.$v.communicationType.communicationTypeNameEn.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-communicationTypeNameUnd"),"value":_vm.communicationType.communicationTypeNameUnd,"title":_vm.$t('CommunicationTypes.nameUnd'),"imgName":'communicationTypes.svg'},on:{"changeValue":function($event){_vm.communicationType.communicationTypeNameUnd = $event}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-communicationTypeDescriptionAr"),"errors":_vm.errors_communicationTypeDescriptionAr,"value":_vm.communicationType.communicationTypeDescriptionAr,"title":_vm.$t('CommunicationTypes.descriptionAr'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.communicationType.communicationTypeDescriptionAr = $event;
            _vm.$v.communicationType.communicationTypeDescriptionAr.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-communicationTypeDescriptionEn"),"errors":_vm.errors_communicationTypeDescriptionEn,"value":_vm.communicationType.communicationTypeDescriptionEn,"title":_vm.$t('CommunicationTypes.descriptionEn'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.communicationType.communicationTypeDescriptionEn = $event;
            _vm.$v.communicationType.communicationTypeDescriptionEn.$touch();}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-communicationTypeDescriptionUnd"),"value":_vm.communicationType.communicationTypeDescriptionUnd,"title":_vm.$t('CommunicationTypes.descriptionUnd'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.communicationType.communicationTypeDescriptionUnd = $event}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-communicationTypeNotes"),"value":_vm.communicationType.communicationTypeNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.communicationType.communicationTypeNotes = $event}}})],1)]),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }